.text-teache {
  color: #817eb7;
}
.bg-teache {
  background-color: #6460f2;
}

.unreadMessagesContainer {
  position: absolute;
  top: -10px;
  right: -10px;
}
.unreadMessages {
  color: #fef2f2;
  background-color: #ef4444;
  font-size: 0.8rem;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
}
