.image-label {
  background-color: #dde9ff;
  border-radius: 8px;
  height: 36px;
  color: #807cd6;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding-inline: 5px;
  margin-top: 6px;
  margin-inline: auto;
  cursor: pointer;
}

.image-preview-container {
  width: -moz-fit-content;
  width: fit-content;
  max-height: 90vh;
  background-color: #fff;
  border-radius: 8px;
  margin-inline: auto;
  margin-left: -23%;
  padding: 1rem;
}
.image-preview-container .image-crop-container {
  display: flex;
  gap: 1rem;
}
.image-preview-container .image-crop-container .crop-container {
  margin: 0;
  border-radius: 8px;
  overflow: hidden;
  width: 350px;
  height: -moz-fit-content;
  height: fit-content;
}
.image-preview-container .image-crop-container .crop-container .image-to-crop {
  width: 350px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  margin-inline: auto;
}
.image-preview-container .save-button {
  all: unset;
  background-color: #dde9ff;
  border-radius: 8px;
  height: 36px;
  color: #807cd6;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding-inline: 0.8rem;
  cursor: pointer;
  margin-left: auto;
}

.mockup-container {
  background-color: #fff;
  border: 1px solid #f1f0fb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.mockup-container .mockup-header {
  position: relative;
  background-blend-mode: overlay;
}
.mockup-container .mockup-header .mockup-header-preview {
  width: 350px;
  height: 196.88px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #554df1;
  background-blend-mode: overlay;
}
.mockup-container .mockup-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 5px);
  background-color: rgba(0, 0, 0, 0.212);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.mockup-container .mockup-header .mockup-header-element {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-inline: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.mockup-container .mockup-header .mockup-header-element__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.mockup-container .mockup-header .mockup-header-element__top .back-button {
  background-color: #fff;
  color: #000000;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.mockup-container .mockup-header .mockup-header-element__top .title-container {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-shadow: 1px 1px 2px rgb(59, 59, 59);
}
.mockup-container .mockup-header .mockup-header-element__top .title-container .title {
  all: unset;
  font-weight: 500;
  font-size: 1.6rem;
  color: #f4f3f9;
  text-transform: capitalize;
}
.mockup-container .mockup-header .mockup-header-element__top .title-container .subtitle {
  all: unset;
  font-size: 1rem;
  color: #f4f3f9;
}
.mockup-container .mockup-header .mockup-header-element__top .menu {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4f3f9;
  text-shadow: 1px 1px 2px rgb(59, 59, 59);
}
.mockup-container .mockup-header .mockup-header-element__middle {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.mockup-container .mockup-header .mockup-header-element__middle .search-bar {
  margin: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-inline: 0.8rem;
  height: 40px;
  width: 75%;
  border-radius: 8px;
  gap: 0.2rem;
  overflow: hidden;
  color: #a7a6ce;
}
.mockup-container .mockup-header .mockup-header-element__middle .search-bar > input {
  height: 100%;
  outline: none;
  border: none;
}
.mockup-container .mockup-header .mockup-header-element__middle .search-bar > input::-moz-placeholder {
  color: #a7a6ce;
}
.mockup-container .mockup-header .mockup-header-element__middle .search-bar > input::placeholder {
  color: #a7a6ce;
}
.mockup-container .mockup-header .mockup-header-element__middle .filter-button {
  background-color: #ff827a;
  color: #fff;
  width: 25%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-shadow: 1px 1px 2px rgb(59, 59, 59);
}
.mockup-container .mockup-header .mockup-header-element__bottom {
  background: #fff;
  color: #a7a6ce;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 0.6rem;
  border-radius: 8px;
  margin-bottom: -5px;
  font-weight: 700;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #fff;
}
.mockup-container .mockup-header .mockup-header-element__bottom .private {
  height: 45px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #554df1;
  position: relative;
}
.mockup-container .mockup-header .mockup-header-element__bottom .private:after {
  content: " ";
  position: absolute;
  width: 100%;
  background-color: #554df1;
  height: 4px;
  padding: 0.1px;
  bottom: 0;
  border-radius: 8px;
}
.mockup-container .mockup-header .mockup-header-element__bottom .group {
  height: 45px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mockup-container .mockup-body {
  padding-inline: 0.8rem;
  padding-block: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 350px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.mockup-container .mockup-body::-webkit-scrollbar {
  display: none;
}
.mockup-container .mockup-body .user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #f1f0fb;
  padding-inline: 1rem;
  padding-block: 0.6rem;
  border-radius: 8px;
}
.mockup-container .mockup-body .user-card__avatar > img {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}
.mockup-container .mockup-body .user-card__infos {
  width: 100%;
  font-size: 12px;
  color: #a7a6ce;
}
.mockup-container .mockup-body .user-card__infos .name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mockup-container .mockup-body .user-card__infos .name-container .name {
  display: flex;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  text-transform: capitalize;
}
.mockup-container .mockup-body .user-card__infos .name-container .location {
  text-transform: capitalize;
}
.mockup-container .mockup-body .user-card__infos .job {
  font-size: 11px;
}
.mockup-container .mockup-body .user-card__infos .review-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.mockup-container .mockup-body .user-card__infos .review-container .stars {
  display: flex;
  align-items: center;
  color: #ff827a;
}
.mockup-container .mockup-body .user-card__infos .review-container .stars > p {
  margin: 0;
  line-height: 24px;
}
.mockup-container .mockup-body .user-card__infos .review-container .review {
  margin: 0;
  line-height: 24px;
  margin-right: auto;
}
.mockup-container .mockup-body .user-card__infos .review-container .price {
  margin: 0;
  line-height: 24px;
  font-size: 13px;
  color: #554df1;
}
.mockup-container .mockup-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #9a99a8;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #f6f6f6;
  width: 100%;
}
.mockup-container .mockup-footer > * {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}